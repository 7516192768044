import { apiClient, getRequest, severityCode } from "./httpClient";

const getAddressTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`addressableobject/addresstypes`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getAddressableObjectTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`addressableobject/types`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getAddressableObjectSubTypesHor = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`addressableobject/subtypeshor`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getAddressableObjectSubTypesVer = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`addressableobject/subtypesver`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getAddressableObjectRoofTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`addressableobject/rooftypes`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getAddressSourceTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`addressableobject/addresssourcetypes`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getConstructionSourceTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`construction/sourcetypes`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getLocationTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`location/types`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getLabelSources = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`location/labelSources`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getInstallationGroups = async () => {
  return getRequest(`installation/groups`);
};

const getInstallationTypes = async () => {
  return getRequest(`installation/types`);
};

const getInstallationProductTypes = async () => {
  return getRequest(`installation/productTypes`);
};

const getInstallationControlTypes = async () => {
  return getRequest(`installation/controlTypes`);
};

const getInstallationDetailTypes = async () => {
  return getRequest(`installation/detailTypes`);
};

const getInstallationSubDetailTypes = async () => {
  return getRequest(`installation/subDetailTypes`);
};

const getInstallationCollaborationTypes = async () => {
  return getRequest(`installation/collaborationTypes`);
};

const getInstallationDeliveryTypes = async () => {
  return getRequest(`installation/deliveryTypes`);
};

const getInstallationInputSources = async () => {
  return getRequest(`installation/inputSources`);
};


const getRegisterTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`energy/registertypes`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getRegisterEnergyTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`energy/energytypes`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getRegisterUnitTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`energy/unittypes`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getEuPrecisionTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`energy/euprecisions`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getRegisterSources = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`energy/registersources`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getEedTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`location/eedtypes`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getNta8800Functions = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`location/nta8800functions`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getEcmFunctions = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`location/ecmBuildingFunctions`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getWeiiBldDecrees = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`location/weiiBldDecrees`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getWeiiBldCategories = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`location/weiiBldCategories`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getConstructionBoundaryTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`construction/boundaryTypes`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getConstructionKinds = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`construction/kinds`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getConstructionInsulationCategory = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`construction/insulationCategories`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getPartyRoles = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`party/roles`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getPartySourceTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`party/sourceTypes`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getMeasureGroups = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`ecm/appliedgroups`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getCostSourceTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`ecm/costSourceTypes`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getNlSfbCategories = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`ecm/nlSfbCategories`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getStartingPointAmbitions = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`location/startingPointAmbitions`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getStartingPointCondition = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`location/startingPointConditions`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getStartingPointStrategies = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`location/startingPointStrategies`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

// const getWeiiBldCategoriesByDecreeKey = async (weiiBldDecreeKey) => {
//   let errorResponse = undefined;
//   const response = await apiClient.get(`location/weiiBldCategories/${weiiBldDecreeKey}`).catch(error => {
//     errorResponse = error.toString();
//   });
//   return { data: response ? response.data : undefined, error: errorResponse };
// }

export {
  getAddressTypes,
  getAddressableObjectTypes,
  getAddressableObjectSubTypesHor,
  getAddressableObjectSubTypesVer,
  getAddressableObjectRoofTypes,
  getAddressSourceTypes,
  getLabelSources,
  getInstallationGroups,
  getLocationTypes,
  getInstallationTypes,
  getInstallationProductTypes,
  getInstallationControlTypes,
  getInstallationDetailTypes,
  getInstallationSubDetailTypes,
  getInstallationCollaborationTypes,
  getInstallationDeliveryTypes,
  getInstallationInputSources,
  getRegisterTypes,
  getRegisterEnergyTypes,
  getRegisterUnitTypes,
  getRegisterSources,
  getEedTypes,
  getNta8800Functions,
  getEcmFunctions,
  getWeiiBldDecrees,
  getWeiiBldCategories,
  getConstructionSourceTypes,
  getConstructionBoundaryTypes,
  getConstructionKinds,
  getConstructionInsulationCategory,
  getPartyRoles,
  getPartySourceTypes,
  getMeasureGroups,
  getEuPrecisionTypes,
  getCostSourceTypes,
  getNlSfbCategories,
  getStartingPointAmbitions,
  getStartingPointCondition,
  getStartingPointStrategies,
  severityCode
  // getWeiiBldCategoriesByDecreeKey
};
